.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-white;
        font-weight: $font-weight-medium;
        font-size: $font-size;
        text-decoration: none;
        padding: $font-size-smaller $font-size-big;
        @media screen and (max-width: 1320px) {
          font-size: 0.95rem;
          padding-left: $font-size-small;
          padding-right: $font-size-small;
        }
      }
    }
    li.menu-item-has-children {
      position: relative;
      padding-right: 0.5rem;
      .sub-menu {
        background-color: $color-primary-dark;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: none;
        position: absolute;
        box-shadow: $box-shadow;
        min-width: 220px;
        width: max-content;
        top: 2.5rem;
        z-index: 2;
        border-radius: 5px;
        li {
          a {
            color: $color-background;
            font-size: $font-size-small;
            padding: 0.5rem 1.25rem;
            font-weight: $font-weight-normal;
            display: block;
            text-decoration: none;
            font-size: $font-size;
            &:hover {
              background-color: $color-primary;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.nav-primary {
  .menu-item-has-children {
    margin-right: $font-size;
    @media screen and (max-width: 1320px) {
      margin-right: 0.4rem;
    }
    @media screen and (max-width: 1154px) {
      margin-right: 0.5rem;
    }
    & > a {
      &::before {
        content: "";
        position: absolute;
        display: block;
        background: url("../assets/ico/ico-chevron-down.svg") no-repeat center
          center;
        background-size: cover;
        width: 10px;
        height: 7px;
        opacity: 1;
        right: 6px;
        top: 10px;
      }
    }
  }
}

.nav-contact {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        @include button;
        // padding: 0.5rem 1.25em;
        background: $color-secondary;
        border-color: $color-secondary;
        color: $color-dark;
        transition: $trans;
        &:hover {
          background-image: linear-gradient(
            340deg,
            $color-secondary 0%,
            $color-primary-light 100%
          );
          color: $color-white;
          box-shadow: $box-shadow-small;
          transform: translateY(-2px);
          transition: $trans;
        }
      }
    }
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-background;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;
      //padding-right: 2.65rem;
      //padding-top: 1rem;
      text-align: right;
      i {
        color: $color-primary;
        transition: $trans;

        &:hover {
          color: $color-primary-light;
          transition: $trans;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    //padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.7rem;
      line-height: 1;
      & > a {
        font-size: 2rem;
        font-weight: $font-weight-medium;
        text-decoration: none;
        color: $color-primary;

        transition: $trans;

        &:hover {
          color: $color-primary-light;
          transition: $trans;
        }
        @media screen and (max-width: 767px) {
          font-size: 1.875rem;
        }
        @media screen and (max-width: 480px) {
          font-size: 1.75rem;
        }
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        margin-top: $space-05;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-secondary;
            color: $color-background !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: 4px;
            display: inline-block;
            font-weight: $font-weight-medium;
            &:hover {
              background-color: $color-secondary !important;
            }
            @media screen and (max-width: 767px) {
              font-size: 1.25rem;
            }
            @media screen and (max-width: 480px) {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }

  &__rs {
    display: flex;
    justify-content: center;

    .social-icon {
      font-size: 32px !important;
      color: $color-primary !important;
      @media screen and (max-width: 767px) {
        font-size: 28px !important;
      }
      &:hover {
        color: $color-primary-light !important;
      }
    }
  }
  &__logo {
    display: flex;
    width: 260px;
    height: auto;
    margin: 2rem auto 0 auto;
    @media screen and (max-width: 480px) {
      width: 220px;
    }
    path {
      fill: #7a7367;
    }
    rect {
      fill: #7a7367;
    }
  }
}
