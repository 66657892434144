.cta {
  // margin: $space-01 0;
  background-color: $color-primary-dark;
  padding: $space-5 0;
  height: auto;
  z-index: 0;
  @media screen and (max-width: 767px) {
    padding: $space-1 0;
  }

  &__container {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__logo {
    margin-bottom: $space-1;
    img,
    svg {
      width: 190px;
      height: auto;
    }
  }

  &__content {
    color: $color-white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: $trans;
    text-align: center;
    @media screen and (max-width: 767px) {
      transition: $trans;
      padding: $space-4 0;
    }
    @media screen and (max-width: 575px) {
      transition: $trans;
      padding: $space-3 0;
    }
  }

  &__pretitulo {
    font-size: $font-size;
    margin-bottom: $font-size;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      margin-bottom: $space-05;
    }
  }

  &__titulo {
    font-size: $display-2;
    font-family: $typo-secondary;
    line-height: 1.4;
    text-align: center;
    margin-bottom: $font-size;
    @media screen and (max-width: 1091px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 575px) {
      line-height: 1.2;
    }
    @media screen and (max-width: 480px) {
      font-size: $header-2;
    }
  }

  &__descripcion {
    font-size: $font-size-big;
    text-align: center;
    margin-bottom: $space-1;
  }

  &__actions {
    margin-top: $space-1;
  }
}
