.galeria {
  padding: $space-0 0 $space-3 0;
  @media screen and (max-width: 810px) {
    padding-top: $space-3;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 2px;
    @media screen and (max-width: 991px) {
      gap: $space-1 $font-size;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-01;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
    }
  }

  div.galeria__item > div {
    width: 100%;
    height: 100%;
  }

  &__item {
    @media screen and (min-width: 768px) {
      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/3;
      }
      &:nth-child(7) {
        grid-column: 2/4;
        grid-row: 3/5;
      }
      &:nth-child(13) {
        grid-column: 3/5;
        grid-row: 5/7;
      }
      &:nth-child(17) {
        grid-column: 2/4;
        grid-row: 7/9;
      }
      &:nth-child(21) {
        grid-column: 1/3;
        grid-row: 9/11;
      }
      &:nth-child(27) {
        grid-column: 2/4;
        grid-row: 11/13;
      }
      &:nth-child(33) {
        grid-column: 3/5;
        grid-row: 13/ 15;
      }
      &:nth-child(37) {
        grid-column: 2/4;
        grid-row: 15 / 17;
      }
      &:nth-child(41) {
        grid-column: 1/3;
        grid-row: 17/19;
      }
      &:nth-child(47) {
        grid-column: 2/4;
        grid-row: 19/21;
      }
      &:nth-child(53) {
        grid-column: 3/5;
        grid-row: 21/23;
      }
      &:nth-child(57) {
        grid-column: 2/4;
        grid-row: 23/25;
      }
      &:nth-child(61) {
        grid-column: 1/3;
        grid-row: 25/27;
      }
    }
  }

  &__item-img {
    aspect-ratio: 16/11;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
