.wpcf7 {
  //margin: 0rem 0 2rem;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: $font-weight-medium;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-text-body;

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
  color: $color-white;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    display: none;
  }
}

.grecaptcha-badge {
  bottom: 28px !important;
}

///////////////////////////////////////////////
//  Complianz                                /
/////////////////////////////////////////////
.cmplz-manage-consent {
  right: 80px !important;
  background-color: #fff !important;
  color: $color-primary !important;
  //display: none;
}

.cmplz-cookiebanner {
  .cmplz-categories {
    .cmplz-category {
      background-color: transparent !important;
      border: 1px solid $color-primary !important;
      .cmplz-always-active {
        color: $color-secondary !important;
        font-weight: $font-weight-normal !important;
      }
      .cmplz-icon {
        display: flex;
        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }
  }
}
