@mixin button {
  padding: 1rem 1.5rem;
  font-size: $font-size;
  border-radius: $border-radius-botones;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  @media screen and (max-width: 767px) {
    padding: 0.8rem 1.625rem;
  }
  &--lg {
    padding: 1.6rem 2.625rem;
    @media screen and (max-width: 767px) {
      padding: 0.5rem 1.475rem;
    }
  }
  &--sm {
    padding: 1rem 1.625rem;
    font-size: $font-size;
    @media screen and (max-width: 767px) {
      padding: 0.8rem 1.25rem;
    }
  }
}

@mixin img-round {
  border-radius: 2rem;
  box-shadow: $box-shadow;
}

@mixin bg-gradient {
  background-color: $color-secondary;
  background-image: linear-gradient(
    160deg,
    $color-secondary 0%,
    $color-primary-light 100%
  ) !important;
}

@mixin grid-3 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: $space-3 $font-size * 2;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-2;
  }
  .thumb-tractament,
  .thumb-equip {
    grid-column: span 2;
  }
  &--1 {
    .thumb-tractament:nth-last-child(1),
    .thumb-equip:nth-last-child(1) {
      grid-column: 3 / span 2;
      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &--2 {
    .thumb-tractament:nth-last-child(2),
    .thumb-equip:nth-last-child(2) {
      grid-column: 2 / span 2;
      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
    .thumb-tractament:nth-last-child(1),
    .thumb-equip:nth-last-child(1) {
      grid-column: 4 / span 2;
      @media screen and (max-width: 575px) {
        grid-column: 1 / span 2;
      }
    }
  }
}
