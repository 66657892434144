.sfeat {
  background-color: $color-secondary;
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__container {
  }

  &__titles {
  }

  &__title {
    font-size: $header-1;
    color: $color-primary-dark;
    font-family: $typo-secondary;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
      line-height: 1.2;
    }
  }

  &__subtitle {
    font-size: $header-3;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
  }

  &__actions {
  }
}

.feat {
  display: flex;
  flex-direction: column;
  gap: $space-2;
  &__item {
    display: flex;
  }

  &__card {
    width: 100%;
    display: flex;
    gap: $space-4;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__card-img {
    display: block;
    img {
      aspect-ratio: 16 / 12;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $border-radius-min;
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--start {
      justify-content: flex-start;
    }
  }

  &__card-pretitle {
    color: $color-primary;
    color: $color-secondary;
    text-transform: uppercase;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-1;
    font-weight: $font-weight-bold;
    line-height: 1;
    color: $color-dark;
    margin-bottom: 1rem;
    @media screen and (max-width: 810px) {
      font-size: $header-1-res;
    }
  }

  &__card-description {
    margin-bottom: $space-1;
    color: $color-grey-1;
    line-height: 1.5;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        position: relative;
        padding-left: $space-1;
        &::before {
          position: absolute;
          left: 0;
          top: 3px;
          display: inline-block;
          content: "";
          color: $color-text-body;
          vertical-align: -0.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
          background-repeat: no-repeat;
          background-size: 1rem 1rem;
          width: 1rem;
          height: 1rem;
          // filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__card-actions {
    // margin-top: $space-01;
  }
}
