.footer {
  padding: $space-3 0 $space-2;
  background-color: $color-primary;
  color: $color-white;
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__content {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      gap: $space-2;
    }
    @media screen and (max-width: 780px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: $space-3;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: $space-2;
    }
  }

  &__item {
  }

  &__item--cita {
  }
}

.widget-footer {
  &__title {
    color: $color-white;
    margin-bottom: $space-1;
    font-family: $typo-secondary;
    font-size: $header-2;
  }
  .textwidget {
    font-size: $font-size-small;
  }
  a {
    color: $color-secondary;
    text-decoration: none;
  }
  .menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: $space-05;
      align-items: center;
    }
    & > li.menu-item {
      a {
        color: $color-white;
        font-weight: $font-weight-medium;
        font-size: $font-size;
        text-decoration: none;
        &:hover {
          color: $color-secondary;
        }
        @media screen and (max-width: 991px) {
          font-size: $font-size-small;
        }
      }
    }
  }
}

.icono-rs {
  display: flex;
  align-items: center;
  gap: $space-01;
  justify-content: flex-start;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.625rem;
    height: 3.625rem;
    background-color: $color-background;
    border-radius: $border-radius-max;
    border: 1px solid $color-primary;
    color: $color-primary;
    font-size: $font-size-big;
    font-weight: bold;
    text-decoration: none;
  }
}

.nav-legal {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    gap: $space-1;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: $space-05;
      align-items: center;
    }
    & > li.menu-item {
      a {
        color: $color-white;
        font-weight: $font-weight-normal;
        font-size: $font-size;
        text-decoration: none;
      }
    }
  }
}

.copyright {
  border-top: 1px dashed $color-white;
  color: $color-white;
  margin-top: $space-3;
  &__container {
    padding-top: $font-size;
    margin-top: $font-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: $space-05;
    }
    @media screen and (max-width: 575px) {
      align-items: center;
    }
  }

  &__copy {
    color: $color-white;
    font-size: $font-size;
    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }
}
