.header {
  position: fixed;
  top: 0;
  z-index: 5;
  transition: $trans;
  width: 100%;
  padding: 1.5rem 0;
  background-color: transparent;
  z-index: 999;
  @media screen and (max-width: 575px) {
    padding-top: $font-size-smaller;
    padding-bottom: $font-size-smaller;
  }

  // &--pages {
  //   .logo {
  //     transition: $trans;
  //     path,
  //     rect {
  //       fill: $color-primary;
  //     }
  //   }
  //   .nav-primary {
  //     li.menu-item a {
  //       color: $color-primary;
  //       &:hover {
  //         color: $color-primary-light;
  //         transition: $trans;
  //       }
  //     }
  //   }
  //   .nav-primary .menu-item-has-children > a {
  //     &::before {
  //       background: url("../assets/ico/ico-chevron-down-primary.svg") no-repeat
  //         center center;
  //       background-size: cover;
  //       transition: $trans;
  //     }
  //     &:hover {
  //       &::before {
  //         background: url("../assets/ico/ico-chevron-down-pl.svg") no-repeat
  //           center center;
  //         background-size: cover;
  //       }
  //     }
  //   }

  //   .header__burger,
  //   .header__social {
  //     i {
  //       @media screen and (max-width: 767px) {
  //         color: $color-primary;
  //         &:hover {
  //           color: $color-primary-light;
  //         }
  //       }
  //     }
  //   }
  // }

  &--sticky {
    padding: $space-01 0;
    position: fixed;
    top: 0;
    transition: $trans;
    box-shadow: $box-shadow;
    background-color: $color-primary;

    .nav-primary .menu-item-has-children > a {
      &::before {
        background: url("../assets/ico/ico-chevron-down-primary.svg") no-repeat
          center center;
        background-size: cover;
        transition: $trans;
      }
      &:hover {
        &::before {
          background: url("../assets/ico/ico-chevron-down-pl.svg") no-repeat
            center center;
          background-size: cover;
        }
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      gap: $font-size;
      justify-content: space-between;
    }
  }
  &__burger {
    margin-left: $space-01;
    line-height: 1;
    @media screen and (min-width: 831px) {
      display: none;
      transition: $trans;
    }
    i {
      color: $color-text-body;
      font-size: 2.5rem;
      transition: $trans;
      @media screen and (max-width: 767px) {
        color: $color-white;
      }
      &:hover {
        color: $color-primary-light;
        transition: $trans;
        @media screen and (max-width: 767px) {
          color: $color-white;
        }
      }
    }
  }

  &__logo {
    .logo {
      width: 200px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 767px) {
        width: 160px;
        transition: $trans;
      }
      @media screen and (max-width: 575px) {
        width: 150px;
        transition: $trans;
      }
    }
  }

  &__nav {
    margin-left: 3rem;
    margin-right: 0;
    transition: $trans;
    margin-left: auto;
    @media screen and (max-width: 830px) {
      display: none;
      transition: $trans;
    }
  }

  &__c-nav {
    transition: $trans;

    @media screen and (max-width: 830px) {
      display: none;
      transition: $trans;
    }
  }

  &__social {
    transition: $trans;

    i {
      font-size: $header-3;
      color: $color-white;
      &:hover {
        color: $color-primary-light;
      }
      @media screen and (max-width: 767px) {
        color: $color-white;
      }
    }
    @media screen and (max-width: 830px) {
      margin-left: auto;
    }
  }
}
