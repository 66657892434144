.hero {
  background-color: $color-primary;
  height: 850px;
  position: relative;
  @media screen and (max-width: 1599px) {
    height: 850px;
  }
  @media screen and (max-width: 1399px) {
    height: 850px;
  }
  @media screen and (max-width: 1200px) {
    height: 750px;
  }
  @media screen and (max-width: 767px) {
    height: 450px;
  }
  &__background {
    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      @media screen and (min-width: 1400px) {
        object-position: center;
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  &__pretitulo {
    font-family: $typo-secondary;
    color: $color-white;
    // text-transform: uppercase;
    font-size: $display-1;
    line-height: 1.2;
    @media screen and (max-width: 767px) {
      font-size: $header-1;
      font-size: 15vw;
    }
  }
  &__titulo {
    color: $color-white;
    font-size: $display-3;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
  }
  &__subtitulo {
    color: $color-white;
    font-size: $font-size-big;
    line-height: 1.7;
    @media screen and (min-width: 992px) {
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
  &__claims {
    margin-bottom: $space-5;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-2;
    }
  }
}
