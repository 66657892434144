.page-title {
  padding-top: $space-5 * 2;
  padding-bottom: $space-1;
  background: $color-primary;
  @media screen and (max-width: 991px) {
    padding: 8rem 0 2rem 0;
  }

  .container {
  }

  &__content {
    text-align: left;
  }

  &__h {
    color: $color-white;
    // font-weight: $font-weight-bold;
    font-size: $display-1;
    font-family: $typo-secondary;
    @media screen and (max-width: 575px) {
      font-size: $header-1-res;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-small;
    color: $color-white;
    // text-transform: uppercase;
    // font-weight: $font-weight-bold;

    a {
      color: $color-white;
      font-weight: $font-weight-normal;
      text-decoration: none;
      &:hover {
        color: $color-primary-light;
      }
    }
  }
}
