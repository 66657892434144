.slick-list {
  border-radius: 3rem;
  padding-bottom: $space-1 !important;
  padding-top: $space-1 !important;
  padding-left: 280px !important;
  padding-right: 0 !important;
  @media screen and (max-width: 991px) {
    padding-bottom: $space-01 !important;
    padding-top: $space-01 !important;
    padding-left: 200px !important;
  }
  @media screen and (max-width: 480px) {
    padding-left: 0 !important;
  }
}

.slick-center {
}

.slick-dots {
  margin-top: $space-1 !important;
  @media screen and (max-width: 991px) {
    margin-top: $space-01 !important;
  }
}

.slick-dots li {
  @media screen and (max-width: 575px) {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

.slick-dots li.slick-active button::before {
  opacity: 0.75 !important;
  color: $color-primary-light !important;
}

.slick-dots li button::before {
  font-size: 2.75rem !important;
  opacity: 0.25 !important;
  color: $color-primary-light !important;
  @media screen and (max-width: 767px) {
    font-size: $font-size * 2 !important;
  }
}

.slick-arrow {
  display: none !important;
  width: 56px !important;
  height: 56px !important;
}

.slick-prev {
  display: none !important;
}

.slick-next {
  @media screen and (min-width: 1241px) {
    transform: translate(60px, -50%) !important;
  }
  @media screen and (max-width: 991px) {
    transform: translate(-10px, -50%) !important;
  }
  @media screen and (max-width: 575px) {
    transform: translate(-26px, -50%) !important;
  }
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.slick-prev::before,
.slick-next::before {
  background-color: $color-primary-light !important;
  color: $color-white;
  padding: 0.25rem;
  border-radius: $space-3;
  font-size: 3rem !important;
}
