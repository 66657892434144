.treball {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0 $space-2 0;
  }
  &__content {
  }

  &__slider {
  }

  &__slider-item {
    padding: 0 $space-05;
  }

  &__slider-img {
    aspect-ratio: 16/11;
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: $box-shadow-slider !important;
  }
}

.filosofia {
  padding: $space-5 0 $space-2 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $space-4;
    margin-bottom: $space-3;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: $space-1;
      margin-bottom: 0;
    }
    ul {
      list-style-type: none;
      padding-left: 10px;
    }
    li {
      margin-bottom: calc($font-size / 2);
      list-style-type: "\2726";
      padding-inline-start: 1ch;
    }
  }
}

.destacat-feat {
  background-color: $color-grey-6;
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &__grid {
  }

  &__caracteristiques {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $space-4;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: $space-2;
    }
  }

  &__caracteristiques-item {
    display: flex;
    flex-direction: column;
    h4 {
      color: $color-primary;
    }
  }

  &__icono {
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size;
    }
    svg,
    img {
      width: auto;
      height: 50px;

      path {
        fill: $color-secondary;
      }
      @media screen and (max-width: 767px) {
        width: 40px;
        height: auto;
      }
    }
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
  }

  &__descripcion {
  }
}
