.tractaments {
  padding: $space-1 0 $space-4;
  &__content {
  }

  &__llista {
  }

  &__grid {
    @include grid-3;
  }
}

.thumb-tractament {
  display: flex;
  flex-direction: column;
  &__thumbnail {
    margin-bottom: $font-size;
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $border-radius-min;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-family: $typo-secondary;
    font-size: $header-2;

    a {
      text-decoration: none;
      color: $color-black;
    }
  }
  &__actions {
    a {
      text-decoration: none;
      color: $color-primary;
      transition: $trans;
      &:hover {
        color: $color-secondary;
        transition: $trans;
      }
    }
  }
}

.tractament {
  padding: $space-3 0 $space-4 0;
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 810px) {
      display: flex;
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__thumbnail {
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $border-radius-min;
    }
  }

  &__info {
    margin-bottom: $space-2;
    p:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}

.tractaments-r {
  padding: $space-4 0;

  &__grid {
    @include grid-3;
  }
}

.cta-contacto {
  &__titulo {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: $font-size;
  }
}
