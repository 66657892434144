.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.page-body {
  p {
    font-size: $font-size;
  }
}

.titulos {
  margin-bottom: $space-03;
  @media screen and (max-width: 767px) {
    margin-bottom: $space-2;
  }
  &--center {
    text-align: center;
  }
  &--35 {
    @media screen and (min-width: 1200px) {
      width: 35%;
      // margin-left: auto;
      // margin-right: auto;
    }
  }
  &--50 {
    @media screen and (min-width: 1200px) {
      width: 50%;
      // margin-left: auto;
      // margin-right: auto;
    }
  }
  &--70 {
    @media screen and (min-width: 1200px) {
      width: 70%;
    }
  }

  &__pretitulo {
    font-size: $font-size;
    //margin-bottom: $font-size;
    color: $color-secondary;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      margin-bottom: $space-05;
    }
  }
  &__titulo {
    font-size: $header-2;
    font-family: $typo-secondary;
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    line-height: 1.4;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 575px) {
      line-height: 1.2;
    }
  }

  &__subtitulo {
    font-size: $header-2;
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin-bottom: $space-01;
    line-height: 1;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
  }

  &__actions {
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      .boton {
        margin-bottom: $space-05;
        width: max-content;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__descripcion {
    color: $color-text-body;
    line-height: 2;
    p:nth-last-child(1) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      h4 {
        margin-top: $space-2;
      }
    }
  }
  &--light {
    .titulos__pretitulo {
      color: $color-white;
    }
    .titulos__titulo {
      color: $color-white;
    }
    .titulos__description {
      color: $color-white;
    }
  }
  &__actions {
    margin-top: $space-2;
  }

  &--mb-0 {
    margin-bottom: 0;
  }
  &--mb-01 {
    margin-bottom: $space-01;
  }
  &--mb-1 {
    margin-bottom: $space-1;
  }
  &--line {
    &::before {
      content: "";
      display: block;
      background-color: $color-primary-light;
      margin: 0 0 60px 0;
      width: 4rem;
      height: 0.6rem;
      @media screen and (max-width: 810px) {
        margin: 0 0 40px 0;
      }
      @media screen and (max-width: 480px) {
        margin: 0 0 25px 0;
      }
    }
  }
}

.title-section {
  color: $color-secondary;
  margin-bottom: $space-1;
  font-family: $typo-secondary;
  font-size: $header-2;
  &--sm {
    font-size: $header-3;
    margin-bottom: $space-01;
  }
}
