.boton {
  @include button;
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-white $color-grey-2,
  "secondary" $color-secondary $color-primary-dark $color-grey-2,
  "white" $color-white $color-grey-2 $color-grey-2,
  "dark" $color-grey-2 $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-dark $color-white $color-grey-2
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background;
    color: $c-text;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}

$degButtons: (
  "degradado" $color-secondary $color-primary-light $color-white,
  "degradado-2" $color-primary-light $color-secondary $color-white
);

@each $nameD, $c-background-1, $c-background-2, $c-textD in $degButtons {
  .boton--#{$nameD} {
    background-image: linear-gradient(
      160deg,
      $c-background-1 0%,
      $c-background-2 100%
    );
    color: $c-textD;
    border-color: $c-background-1;
    transition: $trans;
    &:hover {
      background-image: linear-gradient(
        340deg,
        $c-background-1 0%,
        $c-background-2 100%
      );
      color: $c-textD;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}

.boton--icoStart {
  i {
    margin-right: $font-size / 2;
  }
}

.boton--block {
  width: 100%;
}
