.que {
  padding: $space-5 0;
  background: $color-primary;
  color: $color-white;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &__container {
  }

  &__pretitle {
    font-size: $display-3;
    color: $color-white;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      font-size: $header-3;
      margin-bottom: $font-size;
    }
  }

  &__title {
    color: $color-white;
    font-family: $typo-secondary;
    font-size: $display-1;
    // margin-bottom: $space-2;
    line-height: 1.2;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
  }

  &__description {
    font-size: $font-size-big;
    line-height: 1.7;
    color: $color-white;
    @media screen and (min-width: 992px) {
      max-width: 80%;
    }
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }
  &__actions {
    margin-top: $space-2;
  }
}

.caracteristiques {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &__container {
  }
}

.bloc-icona {
  display: flex;
  align-items: flex-start;
  gap: $space-2;
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  &__item {
    flex: 1;
    @media screen and (max-width: 991px) {
      flex: 2;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      gap: $font-size;
    }
  }
  &__content {
  }
  &__icono {
    margin-bottom: $font-size;

    svg,
    img {
      width: auto;
      height: 50px;
      path {
        fill: $color-secondary;
      }
      @media screen and (max-width: 767px) {
        width: 30px;
        height: auto;
      }
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    color: $color-primary;
    font-size: $header-2;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: calc($font-size / 2);
    }
  }

  &__descripcion {
    font-size: $font-size;
    color: $color-grey-3;
    @media screen and (max-width: 767px) {
      font-size: $header-6;
    }
  }
}

.clinica {
  padding: $space-5 0 $space-2;
  @media screen and (max-width: 767px) {
    padding: $space-3 0 $space-1;
  }
}

.ser-destacats {
  padding: $space-4 0;
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: $space-3;
    }
  }

  &__left {
  }

  &__right {
    align-self: center;
  }

  &__serveis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-2;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
    }
  }
}
