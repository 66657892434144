.contacto {
  padding: $space-3 0;
  background-color: $color-background;
  @media screen and (max-width: 805px) {
    padding: $space-2 0;
  }
  &__content {
    color: $color-text-body;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media screen and (max-width: 805px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__col1 {
  }

  &__contenido {
    // @media screen and (max-width: 805px) {
    //   margin-bottom: $space-2;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: flex-start;
    // }
    // @media screen and (max-width: 480px) {
    //   flex-direction: column;
    //   align-items: flex-start;
    //   justify-content: flex-start;
    // }
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: $color-text-body;

    @media screen and (max-width: 991px) {
      padding-left: $space-2;
    }

    @media screen and (max-width: 805px) {
      padding-left: 0;
    }
  }

  &__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  &__titulo {
    font-size: $header-2;
    margin-bottom: $space-1;
    font-family: $typo-secondary;
    color: $color-primary-dark;
  }

  &__box {
    margin-bottom: $space-2;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    gap: $font-size-smaller;
    font-size: $font-size;
    @media screen and (max-width: 805px) {
      margin-bottom: $space-01;
    }
    a {
      text-decoration: none;
    }
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    a {
      color: $color-primary-dark;
    }
    .bi {
      margin-right: 0.5rem;
      padding: $font-size / 2;
      background-color: $color-primary;
      border-radius: 50px;
      color: $color-white;
    }
  }

  &__horario {
  }

  &__social {
    .zoom-social-icons-list {
      //margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
    }
    .social-icon {
      font-size: 28px !important;
      color: $color-text-body !important;
      @media screen and (max-width: 767px) {
        font-size: 26px !important;
      }
      &:hover {
        color: $color-primary-light !important;
      }
    }
  }
}

.ubicacion {
  background-color: $color-background-footer;
  iframe {
    width: 100%;
    height: 550px;
    @media screen and (max-width: 767px) {
      height: 500px;
    }
    @media screen and (max-width: 575px) {
      height: 400px;
    }
    @media screen and (max-width: 480px) {
      height: 320px;
    }
  }
}

.page-template-page-contacte {
  .wpcf7 {
    padding: $space-2;
    background-color: $color-grey-6;
    fieldset {
      margin-bottom: 1.2rem;
    }
    .wpcf7-form-control-wrap {
      display: block;
    }
    .wpcf7-acceptance {
      margin-left: 0px !important;
      font-size: $font-size-small;
      color: $color-grey-1;
      a {
        color: $color-grey-1;
        font-weight: 400;
        text-decoration: underline;
      }
      .wpcf7-list-item {
        margin-left: 0;
      }
    }
    .wpcf7-list-item {
    }
    .wpcf7-not-valid-tip {
      font-size: $font-size-smaller;
    }

    .wpcf7-submit {
      border: none;
      transition: $trans;
      &[disabled=""] {
        color: $color-grey-3;
        background-color: $color-grey-5;
        transition: $trans;
      }
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: center;
        padding: 1.2rem 2rem;
        font-size: 1.2rem;
      }
    }

    .wpcf7-form-control-wrap {
      .wpcf7-form-control.wpcf7-checkbox {
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem 1rem;
      }
      .wpcf7-list-item {
        margin-left: 0;
      }
    }

    .wpcf7-response-output {
      margin: 1em 0 1em !important;
      padding: 0.5em 1em !important;
      border-radius: 8px !important;
    }

    p {
      line-height: 1.1;
      small {
        font-size: $font-size-smaller;
        color: $color-grey-3;
        line-height: 1.1;
      }
    }
  }

  fieldset {
    legend {
      color: $color-primary-dark;
      font-weight: $font-weight-bold;
      font-size: $header-4;
    }
  }
}
