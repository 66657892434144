.equip {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__content {
  }

  &__llista {
  }

  &__grid {
    @include grid-3;
  }
}

.thumb-equip {
  display: flex;
  flex-direction: column;
  background-color: $color-background;
  border-radius: $border-radius-max;
  box-shadow: $box-shadow;
  &__thumbnail {
    img {
      border-top-left-radius: $border-radius-max;
      border-top-right-radius: $border-radius-max;
      width: 100%;
      height: auto;
    }
  }

  &__info {
    padding: $space-1 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
    line-height: 26px;
    font-size: $font-size;
    a {
      color: $color-black;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  &__especialitats {
    display: flex;
    gap: $space-01;
  }

  &__especialitat {
    color: $color-primary-light;
    text-transform: uppercase;
  }
}
